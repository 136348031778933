import Cookies from "js-cookie";

const componentRoot = document.querySelector("mb-cookie-modal-component");
const shadowRoot = componentRoot.shadowRoot;

const root = shadowRoot.getElementById("mb-cookie-modal");
const showPreferencesButton = shadowRoot.getElementById("show-preferences");

const mainView = shadowRoot.getElementById("main-view");
const preferencesView = shadowRoot.getElementById("preferences-view");
const backButton = shadowRoot.getElementById("back-button");
const acceptAllButtons = shadowRoot.querySelectorAll(".button-accept-all");

let expirationDate, timer;

function checkConsent() {
  if (
    Cookies.get("cookie_valid_until_epoch") == undefined ||
    Cookies.get("functional_cookie_consent") == undefined ||
    Cookies.get("analytical_cookie_consent") == undefined ||
    Cookies.get("third_party_cookie_consent") == undefined
  ) {
    root.showModal();
    showMainView();

    document.body.style.overflowY = "hidden";
    document.body.style.top = `-${window.scrollY}px`;
  } else {
    _setCookiesWithConsent();
    _extendAllCookiesLifetime();
  }
}

function _extendAllCookiesLifetime() {
  if (Cookies.get("expiration_cookies_refresh")) return;

  _setExpirationDateCookie("functional_cookie_consent", Cookies.get("functional_cookie_consent"));
  _setExpirationDateCookie("analytical_cookie_consent", Cookies.get("analytical_cookie_consent"));
  _setExpirationDateCookie("third_party_cookie_consent", Cookies.get("third_party_cookie_consent"));
  _setExpirationDateCookie("cookie_valid_until_epoch", Cookies.get("cookie_valid_until_epoch"));

  Cookies.set("expiration_cookies_refresh", "1", { secure: _isSecure() });
}

function _hide() {
  root.close();
}

function consent() {
  _hide();
  _setCookiesWithConsent();
  window.location.reload();
}

function acceptAllCookies() {
  _setExpirationDateCookie("functional_cookie_consent", "1");
  _setExpirationDateCookie("analytical_cookie_consent", "1");
  _setExpirationDateCookie("third_party_cookie_consent", "1");

  consent();
}

function savePreferences(event) {
  event.preventDefault();

  const formData = new FormData(event.target);
  const data = Object.fromEntries(formData.entries());

  _setExpirationDateCookie("functional_cookie_consent", "1");
  _setExpirationDateCookie("analytical_cookie_consent", data.analytical == "on" ? "1" : "0");
  _setExpirationDateCookie("third_party_cookie_consent", data.thirdParty == "on" ? "1" : "0");

  consent();
}

function _setCookiesWithConsent() {
  if (_hasFunctionalCookieConsent() == "1") _setFunctionalCookies();
  if (_hasAnalyticalCookieConsent() == "1") _setAnalyticalCookies();
  if (_hasThirdPartyCookieConsent() == "1") _setThirdPartyCookies();
  document.dispatchEvent(new CustomEvent('setCookiesCompleted', {bubbles: true}));
}

function _setFunctionalCookies() {
  if (Cookies.get("cookie_valid_until_epoch") == undefined) {
    _setExpirationDateCookie("cookie_valid_until_epoch", expirationDate.getTime());
  }

  watchLoginClick();
  watchRecaptchaSubmit();
}

function watchLoginClick() {
  const menuComponent = document.querySelector("menu-component");
  const loginButton = menuComponent?.shadowRoot.querySelector("#button-login");
  if (loginButton) {
    loginButton.addEventListener("click", setLoginClickCookie);
  }
}

function watchRecaptchaSubmit() {
  const recaptchaSubmit = document.querySelector('#sign-up-for-moneybird-form');
  if (recaptchaSubmit) {
    recaptchaSubmit.addEventListener("signup:success", setSignedUpCookie);
  }
}

function setLoginClickCookie() {
  const clickCount = Cookies.get("login_click_count") || 0;
  Cookies.set("login_click_count", Number(clickCount) + 1, { expires: 30, secure: _isSecure() });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'login_click' });
}

export function setSignedUpCookie() {
  const signUpCount = Cookies.get("sign_up_count") || 0;
  Cookies.set("sign_up_count", Number(signUpCount) + 1, { expires: 30, secure: _isSecure() });
}

function _setAnalyticalCookies() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const secure = _isSecure();
  const referrer = params.referrer;
  const referrer_url = document.referrer;
  const personal_identifier = params.gclid || params.aclid || params.personal_identifier;
  const campaign_id = params.s || params.campaign_id;
  const adgroup_id = params.t || params.adgroup_id;
  const keyword = params.u || params.keyword;
  const ad_id = params.v || params.ad_id;
  const utm_medium = params.utm_medium;
  const utm_campaign = params.utm_campaign;
  const utm_source = params.utm_source;

  if (referrer) Cookies.set("referrer", referrer, { expires: 30, secure: secure });
  if (_setReferrerUrl(referrer_url)) Cookies.set("referrer_url", referrer_url, { expires: 30, secure: secure });

  if (
    campaign_id ||
    adgroup_id ||
    ad_id ||
    keyword ||
    utm_medium ||
    utm_campaign ||
    utm_source
  ) {
    Cookies.set("personal_identifier", personal_identifier || "", { expires: 30, secure: secure });
    if (!isNaN(campaign_id)) Cookies.set("campaign_id", campaign_id, { expires: 30, secure: secure });
    Cookies.set("adgroup_id", adgroup_id || "", { expires: 30, secure: secure });
    Cookies.set("keyword", keyword || "", { expires: 30, secure: secure });
    Cookies.set("ad_id", ad_id || "", { expires: 30, secure: secure });
    Cookies.set("utm_medium", utm_medium || "", { expires: 30, secure: secure });
    Cookies.set("utm_campaign", utm_campaign || "", { expires: 30, secure: secure });
    Cookies.set("utm_source", utm_source || "", { expires: 30, secure: secure });
  }

  if (Cookies.get("landing_page_url") == undefined || Cookies.get("landing_page_name") == undefined) {
    Cookies.set("landing_page_name", window.location.pathname.split("/").filter(String).pop() || "home", { expires: 30, secure: secure });
    Cookies.set("landing_page_url", window.location.href, { expires: 30, secure: secure });
  }
  const isEditor = Cookies.get("is_editor") || Cookies.get("io.prismic.preview");
  if (!isEditor) window[`ga-disable-${gaProperty}`] = false; // Google Analytics - first party
}

function _setThirdPartyCookies() {
  _setFreshWorksSupportWidget();
}

function _setFreshWorksSupportWidget() {
  if (_hasThirdPartyCookieConsent() == "1") {
    _appendFreshworksWidgetScript();
    _loadFreshworksWidgetScript();
    timer = window.setTimeout(() => {
      _setFreshworksWidgetPreferences();
    }, 2000);

    document.body.classList.add("hide-freshdesk");
  }
}

function _appendFreshworksWidgetScript() {
  const freshworksWidgetScript = document.createElement("script");
  freshworksWidgetScript.src =
    "https://euc-widget.freshworks.com/widgets/103000004291.js";
  freshworksWidgetScript.async = true;
  freshworksWidgetScript.defer = true;
  document.head.appendChild(freshworksWidgetScript);
}

function _loadFreshworksWidgetScript() {
  window.fwSettings = { widget_id: 103000004291 };

  if (typeof window.FreshworksWidget !== "function") {
    var n = function () {
      n.q.push(arguments);
    };
    n.q = [];
    window.FreshworksWidget = n;
  }
}

function _setFreshworksWidgetPreferences() {
  FreshworksWidget("hide", "ticketForm", [
    "custom_fields.cf_widget_origin",
    "custom_fields.cf_page_url",
  ]);
  FreshworksWidget("prefill", "ticketForm", {
    custom_fields: {
      cf_widget_origin: "Website",
      cf_page_url: _getCurrentUrl(),
    },
  });
}

function _getCurrentUrl() {
  return window.location.href;
}

function _setExpirationDateCookie(name, value) {
  Cookies.set(name, value, {
    expires: _getDaysToExpirationDate(),
    secure: _isSecure(),
  });
}

function _getDaysToExpirationDate() {
  if (Cookies.get("cookie_valid_until_epoch") != undefined) {
    expirationDate = new Date(Number(Cookies.get("cookie_valid_until_epoch")));
  } else {
    expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  }

  let difference = _dateDifference(new Date(), expirationDate);

  return Number(difference);
}

function _isSecure() {
  return window.location.protocol.indexOf("https") >= 0;
}

function _setReferrerUrl(referrer_url) {
  if (
    typeof referrer_url != "string" ||
    referrer_url.length == 0 ||
    referrer_url.indexOf("moneybird") != -1
  )
    return false;

  return true;
}

function _hasFunctionalCookieConsent() {
  return Number(Cookies.get("functional_cookie_consent"));
}

function _hasAnalyticalCookieConsent() {
  return Number(Cookies.get("analytical_cookie_consent"));
}

function _hasThirdPartyCookieConsent() {
  return Number(Cookies.get("third_party_cookie_consent"));
}

function _dateDifference(start, end) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const startUTC = Date.UTC(
    start.getFullYear(),
    start.getMonth(),
    start.getDate()
  );
  const endUTC = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((endUTC - startUTC) / MS_PER_DAY);
}

function showPreferences() {
  mainView.classList.add("hidden");
  preferencesView.classList.remove("hidden");
  backButton.classList.remove("hidden");
}

function showMainView() {
  mainView.classList.remove("hidden");
  preferencesView.classList.add("hidden");
  backButton.classList.add("hidden");
}

showPreferencesButton.addEventListener("click", showPreferences);
backButton.addEventListener("click", showMainView);
preferencesView.addEventListener("submit", savePreferences);
acceptAllButtons.forEach((button) =>
  button.addEventListener("click", acceptAllCookies)
);

if (window.location.pathname !== '/cookies/') {
  checkConsent();
}
